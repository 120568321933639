import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const SliceCompany = createSlice({
  name: 'company',
  initialState: {
    name: 'Heemang Emotional Wellness',
    url: 'https://heemang-emotional-wellness.com',
    locations: [{
      address1 : '',
      address2 : '',
      city : '',
      province : 'string',
      country : 'string',
      zip : 'string',
    }]
  } as CompanyInfo,
  reducers: {}
});

export const {

} = SliceCompany.actions;

export default SliceCompany.reducer;

