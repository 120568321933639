import { configureStore } from '@reduxjs/toolkit'
import SliceCompany from "./SliceCompany";
// import mockup, {gList} from "../mockup";
// import mockSlice from "./mockSlice";
// import mockGameSlice from "./mockGameListSlice";
// import {rootAPI} from "../services/root";
// import { gameListAPI } from '../services/gameList';

const store = configureStore({
  reducer: {
    company : SliceCompany
    // [rootAPI.reducerPath]: rootAPI.reducer,
    // [gameListAPI.reducerPath]: gameListAPI.reducer,
    // root1: mockSlice.reducer,
    // gameList1: mockGameSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware()
    // .concat(roomAPI.middleware)
    // .concat([roomAPI.middleware, chatAPI.middleware])
    .concat([
      // rootAPI.middleware,
      // gameListAPI.middleware,
    ])
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
