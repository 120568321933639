import  i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import ko from "./ko.json";

export const defaultNS = "ko";
export const resources = {
  en, ko,
} as const;

i18n.use(initReactI18next).init({
  lng: "ko",
  // ns: ["en", "ko"],
  // defaultNS,
  resources: {
    en,
    ko,
  },
  interpolation: {
    escapeValue: false
  }
});

export default i18n;