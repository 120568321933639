import withRouter from "./withRouter";
import {useAppSelector} from "../hooks";

const Copyright = props =>{
  const company:CompanyInfo = useAppSelector((state) => state.company);

  return (<p>
    &copy; {new Date().getFullYear()} {company.name}
  </p>);
};

export default withRouter(Copyright);