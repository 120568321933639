
import React, {useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {Button} from 'react-bootstrap';
import {Routes, Route, Link} from 'react-router-dom';
import withRouter from "./components/withRouter";

import './assets/scss/theme.scss';
// import '../sass/style.scss';
import {func} from "prop-types";
import { useTranslation } from 'react-i18next';
import Login from "./pages/Login";
import ForgotPwd from "./pages/ForgotPwd";
import Signup from "./pages/Signup";
import Profile from "./pages/Profile";

const dropSupported = ('draggable' in window.document.createElement('span'));


let inputIntv:any|null = null;
let inputCurVal:string|null = null;
let delaySec = 560;

const _date = new Date();
const _fullscreen = (window as any).screenfull;
const canFullscreen = (_fullscreen && _fullscreen.enabled) ? true : false;

let receiveMessage;

(window as any).addEventListener("message", (e)=>{
  if(receiveMessage) receiveMessage(e);
}, false);

function App(props) {
  // called every time dom is updated
  console.log('app', props);

  // console.log('root obj', JSON.stringify(props.root));

  // const dispatch = useAppDispatch();
  // dispatch(rootAPI.util.invalidateTags(['root']));

  const { t, i18n } = useTranslation();



  return (
    <div>
      <Routes>
        <Route path={'/'} element={<Login />} />
        <Route path={'/forgot-password'} element={<ForgotPwd />} />
        <Route path={'/signup'} element={<Signup />} />
        {/*<Route path={'/profile'} element={<Profile />} />*/}
        {/*<Route path={'/chat'} element={<Chat />} />*/}
        {/*<Route path={'/chat/:id'} element={<Chat />} />*/}
      </Routes>
    </div>
  )
}

export default App;
