// import 'regenerator-runtime/runtime'
import React from 'react'
import * as ReactDOMClient from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
// import { ThemeProvider } from '@mui/material/styles'

// import './index.scss'
import App from './App';
// import reportWebVitals from './reportWebVitals'
import store from './stores';

import "./i18n/i18n";

const root = ReactDOMClient.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
